<template>
  <span hidden="true">{{ currentUpdateId }}</span>
  <Message
    v-if="!currentMetricsSetted && notInBidrules"
    severity="info"
    :closable="true"
    :sticky="true"
  >
    In order to see the Adjusted Revenue and Estimated Advertising Cost of Revenue values, you first need to assign  <router-link
      class="underline"
      :to="{name: 'metrics', query}"
    >
      Metrics
    </router-link> to your campaigns.
  </Message>
  <Message
    v-if="!currentProfileDownloaded"
    severity="info"
    :closable="true"
    :sticky="true"
  >
    We are currently uploading the last 60 days of data for this profile. We will send you an email once the download is complete!
  </Message>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  inject: ['reportStatus'],
  props: {
    downloadState: {
      type: Object,
      required: true,
    },
    updateId: {
      type: Number,
      required: true,
    },
    profileMetricsCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentDownloadState: this.downloadState,
      currentProfileMetricsCount: this.profileMetricsCount,
      currentUpdateId: this.updateId,
    };
  },
  computed: {
    currentMetricsSetted() {
      return this.currentProfileMetricsCount > 0;
    },
    notInBidrules() {
      return this.$route.name !== 'bidRules';
    },
    currentProfileDownloaded() {
      if (this.currentDownloadState.firstTimeDownloaded === false) {
        return false;
      }

      const currentProfileData = this.currentDownloadState.profileDownloads?.[this.selectedProfile.profileId] ?? null;
      if (currentProfileData === null) {
        return true;
      }

      return this.currentDownloadState.profileDownloads[this.selectedProfile.profileId].profileDownloaded;
    },
    ...mapGetters(['selectedProfile', 'metrics']),
  },
  watch: {
    updateId: {
      handler(val) {
        this.currentUpdateId = val;
      },
    },
    downloadState: {
      handler(val) {
        this.currentDownloadState = val;
      },
    },
    profileMetricsCount: {
      handler(val) {
        this.currentProfileMetricsCount = val;
      },
    },
  },
};
</script>
